import AvvParser from "./index";
import { uniqueArray } from "@avvoka/shared";
import { toPlainText} from "../dom_utils";

export function getQuestionnaireBody(questions: any[]){
  const avvFormat = new AvvParser.AVVFormat();

  const questionnaires = questions.reduce((obj, question) => {
    if(obj[question.party] == null) obj[question.party] = []
    obj[question.party].push(question);
    return obj;
  }, Object.create(null))

  for(const questionnaire of Object.keys(questionnaires)) {
    const questions = questionnaires[questionnaire] as Backend.Questionnaire.IQuestion[]
    const builder = avvFormat.questionnaire(questionnaire)

    let lastCondition = undefined;
    questions.forEach(q => {
      const fn = builder[q.type];
      if(fn == null) {
        throw new Error(`Cannot save template: because type ${q.type} does not exist in questionnaire builder.`)
      }
      if (q.cond !== lastCondition) {
        if (lastCondition != null) {
          builder.endCondition();
          lastCondition = null;
        }
        if (q.cond !== void 0) {
          lastCondition = q.cond;
          builder.condition(q.cond);
        }
      }
      fn.call(builder, q)
    })
    if(lastCondition != null) builder.endCondition()
    builder.endQuestionnaire();
  }

  return avvFormat.format
}

const getDefaultSectionAtts = (questions: Backend.Questionnaire.IQuestion[]) => {
  const firstSectionIndex = questions.findIndex(q => q.type === 'section')
  if(firstSectionIndex === -1) return questions.map(q => q.att)
  const questionsBeforeFirstSection = questions.slice(0, firstSectionIndex)
  return questionsBeforeFirstSection.map(q => q.att)
}

const moveDefaultQuestionsToTop = (questions: Backend.Questionnaire.IQuestion[], defaultSectionAtts: (string|undefined)[]) => {
  const defaultQuestions = questions.filter(q => defaultSectionAtts.includes(q.att))
  const nonDefaultQuestions = questions.filter(q => !defaultSectionAtts.includes(q.att))
  return defaultQuestions.concat(nonDefaultQuestions)
}

export const mergeQuestionnaires = (questionnaires: string[]): {questions?: Backend.Questionnaire.IQuestion[], questionnaires: string[]} => {
  if(!questionnaires.length) return {}
  const defaultSectionAtts = [] as string[]
  const body = questionnaires.reduce((out, questionnaire) => {
    const parsedQuestionnaire = AvvParser.AVVFormat.store(questionnaire.replaceAll('\r\n', '\n'))
    defaultSectionAtts.push(...getDefaultSectionAtts(parsedQuestionnaire.questions))
    Object.entries(parsedQuestionnaire).forEach(([key, value]) => {
      const isArray = Array.isArray(value)
      if(!out[key]) out[key] = isArray ? [] : {}
      out[key] = isArray ? [...out[key], ...value] : {...out[key], ...value}
    })
    return out
  }, {})

  body['questionnaires'] = uniqueArray(body['questionnaires'])
  body['questions'] = body['questions'].filter((q, i) => {
    return body['questions'].findIndex(quest => {
      if(['section', 'label'].includes(q.type)) {
        return quest.desc == q.desc
      }
      return quest.att == q.att
    }) == i
  })

  body['questions'] = moveDefaultQuestionsToTop(body['questions'], defaultSectionAtts)

  return body
}
